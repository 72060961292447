import { Modal } from 'antd';
import React from 'react';

const PopupToken = (props: any) => {
	const { isModalOpen, handleOk, handleCancel, currentUser, dataToken } = props;
	return (
		<div>
			<Modal
				title={
					<div>
						User token information:{' '}
						<span className="text-red-600">
							{currentUser?.first_name} {currentUser?.last_name}
						</span>
					</div>
				}
				destroyOnClose={true}
				visible={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				className="p-2 rounded-sm"
			>
				<p>
					<b>Email: </b>
					{currentUser?.email}
				</p>
				<p>
					<b>Token: </b>
					{dataToken?.token}
				</p>
				<p>
					<b>Refresh token: </b>
					{dataToken?.refreshToken}
				</p>
			</Modal>
		</div>
	);
};

export default PopupToken;
